
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IOrganization,
        IOrganizationType,
        IUpdateOrganizationPresentationParams,
        IPlaceSuggestion,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import PhoneInput from '@/components/forms/PhoneInput.vue';

    @Component<OrganizationProfilePresentation>({
        components: {
            PlaceInput,
            PhoneInput,
        },
    })
    export default class OrganizationProfilePresentation extends Vue {
        public isSubmittingPresentation: boolean = false;
        public updateLocation: IPlaceSuggestion|null = null;
        public formValid: boolean = false;
        public addressRules = formHelper.getOrganizationAddressRules();
        public videoRules = formHelper.getOrganizationVideoUrlRules();
        public clinicNameRules = formHelper.getOrganizationNameRules();

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @organizationNamespace.Getter('typesList')
        public organizationTypesList!: IOrganizationType[];

        public isLoading: boolean = true;

        public innerModel!: IOrganization;
        public billingMethods = [
            {
                value: 'american_express',
                text: 'American Express',
            },
            {
                value: 'blue_card',
                text: 'Cartes Bancaires',
            },
            {
                value: 'cash',
                text: 'Espèces',
            },
            {
                value: 'check',
                text: 'Chèques',
            },
        ];

        get uri () {
            const orga: IOrganization = this.innerModel;
            return 'https://www.vetolib.fr/'+orga.slug;
        }

        get params(): IUpdateOrganizationPresentationParams {
            return  {
                organization_id: this.innerModel.id,
                name: this.innerModel.name,
                place_id: (this.updateLocation as IPlaceSuggestion).raw_data.place_id,
                organization_type_id: this.innerModel.organization_type.id,
                video: this.innerModel.video,
                presentation: this.innerModel.presentation,
                payments: this.innerModel.payments,
                phone: this.innerModel.phone,
            };
        }

        public submitUpdateOrganization() {
            this.isSubmittingPresentation = true;
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            organizationModule
                .update(this.params as IUpdateOrganizationPresentationParams)
                .then((organization: IOrganization) => {
                    snackbarModule.displaySuccess(`${this.innerModel.name} a bien été modifié(e) !`);
                    this.$emit('input', organization);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingPresentation = false;
                })
            ;
        }

        private data() {
            return {
                innerModel: this.loggedOrganization ? JSON.parse(JSON.stringify(this.loggedOrganization)) : null,
            };
        }

        @Watch('loggedOrganization', {immediate: true})
        private onLoggedOrganizationChange(newVal: IOrganization) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;

            if (this.innerModel && this.innerModel.location) {
                this.updateLocation = {
                    title: this.innerModel.address.label,
                    raw_data: {
                        place_id: this.innerModel.location.external_id,
                    },
                };
            }
        }
    }
